<template>
  <div class="options-container">
    <el-scrollbar v-if="showScrollBar" height="292px">
      <div v-for="op in options" :key="op.value" @click="selectOne(op)" :class="['option-item', { selected: selectedValue === op.value }]">{{ op.label }}</div>
    </el-scrollbar>
    <template v-else>
      <div v-for="op in options" :key="op.value" @click="selectOne(op)" :class="['option-item', { selected: selectedValue === op.value }]">{{ op.label }}</div>
    </template>
  </div>
</template>

<script setup lang="jsx">
import { defineProps, computed, defineEmits, } from 'vue'
import { ElScrollbar } from 'element-plus'

const emit = defineEmits(['change'])
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  selectedValue: {
    type: [String, Number],
    default: ''
  }
})
const showScrollBar = computed(() => {
  const heightTotal = props.options.length * 28 + 12
  return heightTotal > 292
})

function selectOne(item) {
  if (props.selectedValue !== item.value) {
    emit('change', item.value)
  }
}
</script>

<style lang="scss" scoped>
.options-container {
  max-height: 294px;
  min-width: 100%;
  overflow-y: auto;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  .option-item {
    font-size: 13px;
    min-width: 100%;
    height: 28px;
    padding: 0 20px;
    line-height: 28px;
    color: #606266;
    text-align: left;
    white-space: nowrap;
    transition: all .3s;
    box-sizing: border-box;
    cursor: pointer;
    &:first-of-type {
      margin-top: 6px;
    }
    &:last-of-type {
      margin-bottom: 6px;
    }
  }
  .option-item:hover, .option-item.selected {
    background-color: #f5f7fa;
  }
  .option-item.selected {
    color: var(--themeColor, #17a2b8) !important;
    font-weight: 700;
  }
  .el-scrollbar {
    min-width: 100%;
  }
}
</style>