<template>
  <div
    ref="DynamicRadioSelectContainer"
    class="dynamic-down"
  >
    <div
      class="text"
      @click="showOptionsSelect"
    >
      <div class="row-ellipsis">
        {{ showText }}
      </div>
      <el-icon
        :class="['select-icon', {'arrow-up': !show}]"
        style="font-size: 14px;"
      >
        <ArrowUp />
      </el-icon>
    </div>
    <div
      ref="selectBoxRef"
      :class="['select-box', topOrBottom]"
      @click.stop
    />
  </div>
</template>

<script lang="jsx" setup>
import { ref,  createApp, onMounted, onUnmounted,defineEmits, defineProps, toRaw, computed } from 'vue'
import { ArrowUp } from '@element-plus/icons'

import DynamicRadioSelectOptions from './DynamicRadioSelectOptions.vue'

const emit = defineEmits(['change', 'update:modelValue'])
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
})

const show = ref(false)
const optionsInstance = ref()
const selectBoxRef = ref()
const optionsRef = ref()
const DynamicRadioSelectContainer = ref()
const insParent = ref()
const topOrBottom = ref('bottom')
const inner = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
})
const showText = computed(() => {
  return props.options.find(item => item.value === props.modelValue)?.label || props.modelValue
})

onMounted(() => {
  document.body.addEventListener('click', destroyCa)
  insParent.value = document.querySelector('.ins-parent-judge-top-bottom')
})
onUnmounted(() => {
  document.body.removeEventListener('click', destroyCa)
})

function destroyCa(e) {
  let parentNodeElem = e.target
  const dynamicDownOrigin = toRaw(DynamicRadioSelectContainer).value
  let parentIsDynamicDown = false
  let i = 0
  while (parentNodeElem) {
    parentIsDynamicDown = parentNodeElem === dynamicDownOrigin
    if (parentIsDynamicDown || i >= 6) {
      break
    }
    parentNodeElem = parentNodeElem.parentNode
    i ++
  }
  if (parentIsDynamicDown) return
  if (!show.value && !optionsInstance.value) return
  show.value = false
  optionsInstance.value?.unmount()
}

function showOptionsSelect() {
  show.value = !show.value
  if (show.value) {
    optionsInstance.value = createApp({
      render() {
        return <DynamicRadioSelectOptions selectedValue={inner.value} ref={optionsRef} options={props.options} onChange={closeCascader} />
      },
    })
    const parentBottom = insParent.value?insParent.value.getBoundingClientRect().bottom : 0
    const selectBoxBottom = DynamicRadioSelectContainer.value?DynamicRadioSelectContainer.value.getBoundingClientRect().bottom:0
    const optionsHeight = props.options.length > 10 ? 294 : props.options.length * 28 + 22
    if ((parentBottom - selectBoxBottom) < optionsHeight) {
      topOrBottom.value = 'top'
    } else {
      topOrBottom.value = 'bottom'
    }
    optionsInstance.value.mount(selectBoxRef.value)
  } else {
    optionsInstance.value?.unmount()
  }
}
function closeCascader(val) {
  emit('update:modelValue', val)
  emit('change', val)
  show.value = false
  optionsInstance.value?.unmount()
  document.body.addEventListener('click', destroyCa)
}
</script>

<style lang="scss" scoped>
.dynamic-down {
  position: relative;
  width: 100%;
  height: 24px;
  line-height: 24px;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  box-sizing: border-box;
  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    font-size: 13px;
    color: #606266;
    cursor: pointer;
  }
  .row-ellipsis {
    padding-right: 10px;
  }

  .select-box {
    position: absolute;
    left: 0;
    min-width: 100%;
    background: #fff;
    z-index: 10000;
  }
  .select-box.top {
    bottom: calc(100% + 10px);
    
  }
  .select-box.bottom {
    top: calc(100% + 10px);
  }
  .select-icon {
    transition: all .3s;
    &.arrow-up {
      transform: rotate(180deg);
    }
  }
}
</style>
