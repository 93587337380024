<template>
  <div class="top_title">
    <div v-if="invoiceType !='2' && invoiceType !='5'">
      <el-radio :disabled="invoiceType =='2' || invoiceType =='5'" v-model="info.rzzt" :label="1">已认证</el-radio>
      <el-radio :disabled="invoiceType =='2' || invoiceType =='5'" v-model="info.rzzt" :label="2">未认证</el-radio>
    </div>
    <div v-else style="height: 32px;width: 20px;"></div>

    <div class="top_input clearfix">
      <h6>发票代码</h6>
      <el-input style="width:50%;" v-model="info.fpdm" size="small" placeholder="请输入发票代码"></el-input>
    </div>
    <div class="bottom_time clearfix">
      <div class="top_input2 clearfix fl" style="width: 30%;">
        <h6>发票号码</h6>
        <el-input style="width:50%;" v-model="info.fphm" size="small" placeholder="请输入发票号码"></el-input>
      </div>
      <div class="top_input2 clearfix fr">
        <h6>开票日期</h6>
        <el-date-picker type="date" v-model="info.invoiceDate" placeholder="选择日期时间" size="small"></el-date-picker>
      </div>
    </div>
  </div>

  <div class="content invoice" :class="{'special': invoiceType =='2' || invoiceType =='5', 'red': invoiceType =='2' || invoiceType =='5'}">
    <!-- 购方 -->
    <el-row :span="24" class="row_box2">
      <el-col :xs="1" :sm="1" :lg="1">
        <div class="grid-content height_font">
          <p>销方单位</p>
        </div>
      </el-col>
      <el-col :xs="3" :sm="3" :lg="3">
        <div class="grid-content">
          <ul class="list_info weight">
            <li>名称<span class="star">*</span></li>
            <li>纳税人识别号</li>
            <li>地址、电话</li>
            <li>开户行及账号</li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="7" :sm="7" :lg="7">
        <div class="grid-content">
          <ul class="list_info">
            <li>
              <div style="text-align: left;line-height: 24px;">
               <corp-list :borderNone="true" v-model:corpId="info.corpId" v-model:corpName="info.corpName" type="2" :comId="comId"></corp-list>
              </div>
            </li>
            <li class="border_none">
              <el-input class="boxShadowNone" v-model="info.taxNo"></el-input>
            </li>
            <li class="border_none">
              <!-- 海关进口增值税专用缴款通知书 -->
              <el-input class="boxShadowNone" disabled v-if="invoiceType == '6'"></el-input>
              <el-input class="boxShadowNone" v-else v-model="info.addressTel"></el-input>
            </li>
            <li class="border_none">
              <el-input class="boxShadowNone" v-model="info.bankNameNo"></el-input>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="3" :sm="3" :lg="3">
        <div class="grid-content">
          <ul class="list_info">
            <li>
              <span >抵扣状态</span>
            </li>
            <li>
              <span >抵扣方式</span>
            </li>
            <li>外币核算</li>
            <li>是否退税</li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :lg="6">
        <div class="grid-content">
          <ul class="list_info">
            <li>
              <div>
                <el-radio-group :disabled="invoiceType =='2' || invoiceType =='5'" v-model="info.dkzt">
                  <el-radio :label="1">本期抵扣</el-radio>
                  <el-radio :label="2">不予抵扣</el-radio>
                </el-radio-group>
              </div>
            </li>
            <li>
              <div>
                <el-radio-group :disabled="invoiceType =='2' || invoiceType =='5'" v-model="info.dkfs">
                  <el-radio :label="1">一般项目</el-radio>
                  <el-radio :label="2">即征即退</el-radio>
                </el-radio-group>
              </div>
            </li>
            <li class="border_none">
              <span style="float: left;width: 120px">
                <el-select filterable v-model="info.wb" placeholder="请选择外币" size="small" class="boxShadowNone">
                  <template v-for="(item,index) in wbLists">
                    <el-option
                      v-if="item.label != '人民币'"
                      :label="item.label"
                      :value="item.value"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </span>
              <span v-if="info.wb" style="float: left;width: 60px;margin-left:10px;">
                <input
                  style="border:1px solid #ccc;height:26px;font-size:10px"
                  placeholder="外币金额"
                  type="text"
                  v-model.number="info.wbAmount"
                />
              </span>
              <span v-if="info.wb" style="float: left;width: 50px;margin-left:10px;">{{rate_wb}}</span>   
            </li>
            <li>
              <el-radio-group v-model="info.sfts">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="4" :sm="4" :lg="4">
        <div class="input_box">
          <div class="top_font">
            <el-switch v-model="info.whlCb" :active-value='1' :inactive-value='0'></el-switch> 是否文化类成本
          </div>
          <div class="top_font" style="height:40px">
            <subject-list :borderNone="true" subjectName="车船税" width="90%" placeholder="车船税科目" v-model:subjectId="info.ccsSubjectId" v-model:fzhsItemId="info.ccsFzhsId" :comId="comId"></subject-list>
          </div>
          <div class="top_font item_box border_none" style="border-top:0;height: 39px;">
            <el-input placeholder="车船税税额" style="padding-left:15px" type="number" class="boxShadowNone" v-model.number="info.ccs"/>
          </div>
          <div class="top_font item_box" style="border-top:0;border-bottom:none">
            <el-input v-if="info.sfts == 1" placeholder="退税额" class="boxShadowNone" v-model="info.tse"></el-input>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 购方 end -->

    <!-- 中间栏 -->
    <div :key="index" class="item_box item_list">
      <el-row :span="24">
        <el-col :xs="4" :sm="4" :lg="4">
          <div class="grid-content bg-purple">业务类型
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content bg-purple">开票项目
            <!-- <span class='star'>*</span> -->
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">商品编码
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">规格型号</div>
        </el-col>
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content bg-purple">单位</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" >
          <div class="grid-content bg-purple">数量</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" >
          <div class="grid-content bg-purple">单价</div>
        </el-col>
        <el-col :xs="4" :sm="4" :lg="4" v-if="invoiceType == '6'">
          <div class="grid-content bg-purple">完税价格</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" v-if="invoiceType != '6'">
          <div class="grid-content bg-purple">金额
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">税率
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">税额
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" v-if="invoiceType != '6'">
        <div class="grid-content bg-purple">价税合计
            <span class='star'>*</span>
        </div>
        </el-col>
      </el-row>
    </div>
    <!-- 中间栏 end -->
    <!-- items -->
    <div v-for="(item, infoItemIndex) in infoItems" :key="infoItemIndex" class="item_box">
      <div class="close" @click="() => handleDelItem(info.items, infoItemIndex)">
        <i class="iconfont icon-shanchu"></i>
      </div>

      <div class="add" @click="() => handleAddItem(info.items, infoItemIndex)">
        <i class="iconfont icon-jiahao"></i>
      </div>

      <el-row :span="24">
        <!-- 业务类型 (科目)-->
        <el-col :xs="4" :sm="4" :lg="4">
          <div class="grid-content" v-if="!info.id || !item.itemId || !item.itemName">
            <DynamicDown businessIdKey="businessTypeId" :row="item" @change="val => changeCas(val, item)" @changeItemName="val => changeItemName(val, item)" />
          </div>
          <div class="grid-content" v-else-if="!item.showStatus && info.id" @click="item.showStatus = true" style="cursor: pointer;">
             {{ item.itemName }}
          </div>
          <div class="grid-content" v-else-if="item.showStatus && info.id">
            <DynamicDown businessIdKey="businessTypeId" :row="item" @change="val => changeCas(val, item)" @changeItemName="val => changeItemName(val, item)" />
          </div>
        </el-col>
        <!-- 开票项目 -->
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content">
            {{item.name}}
          </div>
        </el-col>
        <!-- 商品编码 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content">
            <DynamicRadioSelect class="boxShadowNone" :options="ssflbmOptions" v-model="item.ssflbm" />
          </div>
        </el-col>
        <!-- 规格型号 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content border_none">
            <el-input class="boxShadowNone" v-model="item.spec" type="text"></el-input>
          </div>
        </el-col>
        <!-- 单位 -->
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content border_none">
            <el-input class="boxShadowNone" v-model="item.unit" type="text"></el-input>
          </div>
        </el-col>
        <!-- 数量 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content border_none">
            <el-input  class="boxShadowNone" v-model="item.count" type="text" @change="changeCount(item)"></el-input>
          </div>
        </el-col>
        <!-- 单价 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content border_none">
            <el-input  class="boxShadowNone" v-model="item.price" type="text" @change="changePrice(item)" :disabled="item.id"></el-input>
          </div>
        </el-col>
        <!-- 海关进口  完税价格 -->
        <el-col :xs="4" :sm="4" :lg="4" v-if="invoiceType == '6'">
          <div class="grid-content border_none">
            <el-input class="boxShadowNone" v-model="item.amount" type="text"></el-input>
          </div>
        </el-col>
        <!-- 金额 -->
        <el-col :xs="2" :sm="2" :lg="2" v-if="invoiceType != '6'">
          <div class="border_none">
            <el-input class="boxShadowNone" v-model="item.amount" type="text" @change="changeAmount(item)"></el-input>
          </div>
        </el-col>
        <!-- 税率 -->
        <el-col :xs="2" :sm="2" :lg="2" >
          <div class="border_none" v-if="invoiceType != 3 && invoiceType != 8">
            <DynamicRadioSelect class="boxShadowNone" :options="taxRateAll" v-model="item.taxRate" @change="(val) => selectShuilv(val,item)" />
          </div>
          <div v-else style="text-align: center;font-size: 12px;line-height:38px">
            免税
          </div>
        </el-col>
        <!-- 税额 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="border_none" v-if="invoiceType != 3 && invoiceType != 8">
            <el-input class="boxShadowNone" v-model="item.tax" type="text"></el-input>
          </div>
          <div v-else style="text-align: center;font-size: 12px;line-height:38px">
            0
          </div>
        </el-col>
        <!-- 价税合计 -->
        <el-col :xs="2" :sm="2" :lg="2" v-if="invoiceType != '6'">
          <div class="border_none">
            <el-input class="boxShadowNone" v-model="item.total" type="text" @change="changeTotal(item)"></el-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="infoIndex !== info.items.length && info.items.length > 200" class="total loadmore" style="border-right: 1px solid #008000;">
      <el-button v-loading="moreLoading" type="primary" link style="color: #008000;">加载更多中&nbsp;<el-icon><Loading /></el-icon></el-button>
    </div>
    <!-- 共计 -->
    <div class="total">
      <el-row :span="24">
        <el-col :xs="16" :sm="16" :lg="16">
          <div class="grid-content bg-purple">合计</div>
        </el-col>
        <el-col :xs="4" :sm="4" :lg="4" v-if="invoiceType == '6'">
          <div class="grid-content bg-purple" style="text-align:left;padding:0 15px">{{sumAmount}}</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" v-else>
          <div class="grid-content bg-purple" style="text-align:left;padding:0 15px">{{sumAmount}}</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple" style="text-align:left;padding:0 15px">{{sumTax}}</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" v-if="invoiceType != '6'">
          <div class="grid-content bg-purple" style="text-align:left;padding:0 15px">{{sumTotal}}</div>
        </el-col>
      </el-row>
    </div>
    <!-- 共计-end -->
  </div>
  <div class="bottom_num">
    发票张数
    <el-input v-model="info.invoiceCount" style="width:100px" size="small" />
    <div v-if="!comId">
      <el-button size="small" style="float:left" @click="changeInvoicePre(info)">上一张</el-button>
      <el-button size="small" style="float:left" @click="changeInvoiceNext(info)">下一张</el-button>
    </div>
    
  </div>
  <div style="float:right"><el-button size="small" :loading="loading" @click="saveInvoice" type="success">保存</el-button></div>
  <div style="float:right"><el-button size="small" :loading="loading" @click="saveInvoice('aa')" type="success" style="margin-right:10px">保存且继续新增</el-button></div>

  <add-inventory
    ref="addInventory"
    @success="addInventorySuccess"
  />
  <add-subject
    ref="addSubject"
    @success="addSubjectSuccess"
  />
  <addAssets
    ref="addAssets"
    :type="typexsjx"
    :tyname="'xzjx'"
    title="关联资产"
    @success="addAssetSuccess"
  />
</template>

<script>
import { wbList, saveInInvoice ,invoiceNextInfo} from '@/api/invoice.js'
import { getPeriodTime } from "@/utils/fun";
import DynamicDown from './DynamicDown'
import addAssets from '@/views/input/assets/components/addAssets.vue'
import DynamicRadioSelect from './DynamicRadioSelect.vue'

import { ssflbmOptions } from '@/components/ssflbm/constants.js'

export default {
  components: {
    DynamicDown,
    addAssets,
    DynamicRadioSelect,
    // BusinessTypeNavie
  },
  props: {
    invoiceType: {//发票类型 1专票 2普票 3 无票 4电专 5电普'  
      type: String,
      default: "1"
    },
    comId: {
      type: Number,
      default: 0
    },
    period: {//账期
      type: String,
      default: ""
    },
    comType:{
      type: String,
      default: "1"      
    }
  },
  data() {
    return {
      infoItems: [{},{}],
      ssflbmOptions: JSON.parse(JSON.stringify(ssflbmOptions)),
      currentRow: null,
      loading: false,
      wbLists: [],
      info: {
        invoiceCount: 1,
        invoiceDate: getPeriodTime(this.period),
        rzzt: 1,
        dkzt: 1,
        dkfs: 1,
        sfts: 0,
        items: [{},{}],
      },
      sumAmount:0,
      // 税率
      taxRateAll: [
        {
          value: 0.17,
          label: "0.17"
        },
        {
          value: 0.16,
          label: "0.16"
        },
        {
          value: 0.13,
          label: "0.13"
        },
        {
          value: 0.10,
          label: "0.10"
        },
        {
          value: 0.09,
          label: "0.09"
        },
        {
          value: 0.06,
          label: "0.06"
        },
        {
          value: 0.05,
          label: "0.05"
        },

        {
          value: 0.03,
          label: "0.03"
        },
        {
          value: 0.015,
          label: "0.015"
        },

        {
          value: 0.0,
          label: "0.00"
        },
        {
          value: 0.01,
          label: "0.01"
        }
      ],
      typexsjx: 0,
      bid: '',
      intersectionObserver: null,
      infoDenominator: 10,
      infoStep: 0,
      infoIndex: 0,
      itemMaxLength: 200,
    }
  },
  computed: {
    // 税额合计
    sumTax() {
      let count = 0;
      this.info.items.map(v => {
        if (v.tax) {
          count += Number(v.tax);
        }
      });
      return Number(count.toFixed(2));
    },
    //  价税总计
    sumTotal() {
      let count = 0;
      this.info.items.map(v => {
        if (v.total) {
          count += Number(v.total);
        }
      });
      return Number(count.toFixed(2));
    },
    rate_wb() {
      let count = 0;
      this.info.items.map(v => {
        if (v.total) {
          count += Number(v.total);
        }
      });
      if (this.info.wbAmount) {
        return Number((count / this.info.wbAmount).toFixed(4));
      } else {
        return 0;
      }
    },
    options() {
      return this.$store.getters['commons/businessType']['in']
    },
  },
  created () {
    this.getWbList()
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      // 如果 intersectionRatio 为 0，则目标在视野外，
      // 我们不需要做任何事情。
      if (entries[0].intersectionRatio <= 0) return;
      this.getInfoItems()
    });
  },
  methods: {
    getInfoItems () {
      const items = this.info.items
      const len = items.length
      if (!len) return
      if (this.infoIndex >= len) {
        this.intersectionObserver.unobserve()
        this.intersectionObserver = null
        return
      }
      
      let last = this.infoIndex + this.infoStep
      if (len - last < this.infoStep * 2) {
        last = len
      }
      this.infoItems = [...this.infoItems, ...items.slice(this.infoIndex, last)]
      this.infoIndex = last
    },
    changeRowIds(ids) {
      this.currentRow.businessTypeId = +ids[0]
      this.currentRow.itemId = ids[1].split('-')[1] * 1
      this.currentRow.fzhsId = ids[1].split('-')[2] * 1
    },
    addAssetSuccess(sign) {
      const ids = [this.bid, this.bid + '-' + sign,]
      this.changeRowIds(ids)
    },
    addInventorySuccess(e) {
      const ids = [this.bid, this.bid + '-' + String(e.id) + '-0',]
      this.changeRowIds(ids)
    },
    addSubjectSuccess(e) {
      const ids = [this.bid, this.bid + '-' + e.sign,]
      this.changeRowIds(ids)
    },
    changeItemName(pathLabels, row) {
      row.itemName = pathLabels.join('/')
    },
    changeCas(val, row) {
      let { ids, node, } = val
      const bid = ids[0]
      this.bid = bid
      this.currentRow = row
      const addId = ids[0] * 1
      let businessTypeInfo = node?.parent?.data

      if (ids[1] === 'addInventory') {
        let data = {
          id: undefined,
          spec: row.spec,
          unit: row.unit,
          type: addId,
        }
        this.$refs.addInventory.init(data)
      } else if (ids[1] === 'addSubject') {
        let data = {
          id: undefined,
          type: 0,
        }
        let useCodes = null
        if (businessTypeInfo) {
          useCodes = businessTypeInfo.useCodes
        }
        this.$refs.addSubject.init(data, useCodes)
      } else if (ids[1] === 'addAssage') {
        //14 固定资产
        //16 无形资产
        //17 待摊费用
        const bidFortypexsjx = {
          14: 1,
          16: 2,
          17: 3,
        }
        this.typexsjx = bidFortypexsjx[bid]
        let param = {
          name: row.name,
          originalValue: ['1', '4'].includes(this.invoiceType) && this.comType == '2' ? row.amount : row.total,
          unit: node?.data.unit,
          type: this.typexsjx,
          transDate: this.$parseTime(this.info.invoiceDate, '{y}-{m}-{d}'),
          count: row.count,
        }
        this.$refs.addAssets.openDialog(param, this.typexsjx, 'glzc')
      }
      if (ids.length > 1) {
        this.changeRowIds(ids)
        row.itemName = node.pathLabels.join('/')
        //存货的 双向绑定一下 型号 单位
        if (addId <= 11 || [20, 21, 22].includes(addId)) {
          row.spec = node.data.spec
          row.unit = node.data.unit
          row.itemType = 'inventory'
        } else {
          row.itemType = 'item'
        }
      }
    },
    changeType(e){
      if(e == '农副产品发票(免税)(9%)'){
        this.info.items.forEach(v=>{
          v.taxRate = 0
        })
      }
    },
    getWbList(){
      wbList({}).then(res => {
        this.wbLists = res.data.data.list;
      });
    },
    init(info){
      this.sumAmount = 0
      if(info){
        this.info = info  
        this.info.items.map(v => {
          if (v.amount && v.count && v.count != 0) {
            v.price = Number((v.amount/v.count).toFixed(2))
          }else if(v.count == 0 || !v.count && v.amount){
            v.price = Number((v.amount).toFixed(2))        
          }
          this.sumAmount += Number((v.amount).toFixed(2))
          this.sumAmount = (this.sumAmount).toFixed(2)*1
        });
        if (this.info.items.length <= this.itemMaxLength) {
          this.infoItems = [...this.info.items]
          // this.$emit('complete')
        } else {
          this.infoStep = Math.ceil(this.info.items.length / this.infoDenominator)
          this.getInfoItems()
          setTimeout(() => {
            // 开始监听
            this.intersectionObserver.observe(document.querySelector(".loadmore"));
          }, 100)
        }
      }else{
        this.info = {
          invoiceCount: 1,
          invoiceDate: getPeriodTime(this.period),
          rzzt: 1,
          dkzt: 1,
          dkfs: 1,
          sfts: 0,
          items: [{},{}],
        }
        this.infoItems = [...this.info.items]
      }
    },
    saveInvoice(param){
      this.info.invoiceType = this.invoiceType;
      this.info.sumTax = this.sumTax;
      this.info.sumTotal = this.sumTotal;
      this.info.sumAmount = this.sumAmount;
      this.info.rate = this.rate_wb;
      this.info.invoiceCount = Number(this.info.invoiceCount)
      this.info.tse = Number(this.info.tse)

      let items = this.info.items.filter(bb => {
        if (!bb.businessTypeId && !bb.itemId) {
          return false;
        } else {
          return true;
        }
      });
      if(items.length == 0){
        this.$message.error("至少一条发票信息");
        return
      }

      this.info.items = items;
      if (!this.info.corpId) {
        this.$message.error("绑定进方单位");
        return;
      }
      let flag = true;
      this.info.items.map(item => {
        item.amount = item.amount?Number(item.amount):0
        item.tax = item.tax?Number(item.tax):0
        if(this.invoiceType == '6'){
          item.total = Number(item.amount) + Number(item.tax);
        }else{
          item.total = item.total?Number(item.total):0
        }
        item.count = item.count?Number(item.count):0
        item.price = item.price?Number(item.price):0
        item.taxRate = item.taxRate || 0
        if (!item.ssflbm) {
          this.$message.error("请完善商品编码");
          flag = false;
          return;
        }
        if (!item.amount) {
          this.$message.error("请完善金额");
          flag = false;
          return;
        }
        if (!item.total) {
          this.$message.error("请完善价税合计");
          flag = false;
          return;
        }
      });
      // this.info.sumTax = this.sumTax;
      if (!flag) {
        return;
      }
      if(this.comId && this.period){
        this.info.comId = this.comId
        this.info.period = this.period
      }
      this.loading = true
      saveInInvoice(this.info).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          if(this.comId && this.period){
            this.$emit("success")
          }else{
            if(this.info.id){
              this.$bus.emit('voucherUpdate')
              this.$bus.emit("invioceInUpdate")
              this.$bus.emit('invioceInUpdate', this.info)
            }else{
              this.$bus.emit('invioceInUpdate', this.info)
            }
          }
          if(param == 'aa'){
            this.sumAmount = 0
            this.info = {
              invoiceCount: 1,
              invoiceDate: getPeriodTime(this.period),
              rzzt: 1,
              dkzt: 1,
              dkfs: 1,
              sfts: 0,
              items: [{},{}],
            }
            this.infoItems = [...this.info.items]
            this.infoIndex = 0
            return
          }else{
            this.infoItems = [...this.info.items]
            this.info.id = res.data.data.info.id
          }
          // this.$store.dispatch('tagsView/delCachedViewByName', "invoice")
          
        }
      })

    },

    handleAddItem(itemArr, infoItemIndex) {
      let taxRate = ''
      if (['3', '8'].includes(this.invoiceType)) {
        taxRate = 0
      }
      const l = {
        subjectId: "", //业务类型
        taxId: "", //税目
        spec: "", // 规格型号
        unit: "", // 单位
        count: "", // 数量
        amount: "", // 金额
        taxRate, // 税率
        tax: "", // 税额
        total: "" // 价税合计
      }
      itemArr.splice(infoItemIndex + 1, 0, l);
      this.infoItems.splice(infoItemIndex + 1, 0, l);
      if (infoItemIndex <= this.infoIndex) {
        this.infoIndex ++
      }
    },
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
      if (this.infoItems.length) {
        this.infoItems.splice(index, 1)
        this.infoIndex --
      }
      this.computedSumAmount()
    },
    // 改变税率
    selectShuilv(e, row) {
      let taxRate = e; // 税率
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (amount) {
        if(taxRate == 0){
          tax = 0;
        }else{
          tax = Number(amount).toFixed(2) * Number(taxRate);
        }
        row.tax = Number(tax.toFixed(2));
        total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
        row.total = Number(total.toFixed(2));
      }
    },
    //输入数量
    changeCount(row){
      if(row.count && row.count != 0){
        if(row.price){
          row.amount =  Number((row.count *1 * row.price *1).toFixed(2))
        }
        if(row.amount){
          row.price = Number((row.amount *1 / row.count *1).toFixed(2))
        }
      }else if(row.count == 0 || !row.count){
        if(row.price){
          row.amount =  Number((row.price *1).toFixed(2))
        }
        if(row.amount){
          row.price = Number((row.amount).toFixed(2))
        }
      }
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (row.taxRate || row.taxRate == 0) {
        tax = Number(amount).toFixed(2) * Number(row.taxRate);
        row.tax = Number(tax.toFixed(2));
        total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
        row.total = Number(total.toFixed(2));
      }
      if(this.invoiceType == 3 || this.invoiceType == 8){
        row.total = Number(amount).toFixed(2) *1
      }
      this.computedSumAmount()
    },
    //输入价格
    changePrice(row){
      if(row.count && row.count != 0){
        if(row.price){
          row.amount =  Number((row.count *1 * row.price *1).toFixed(2))
        }else if(!row.price || row.price == 0){
          row.amount =  0
        }
      }else if(row.count == 0 || !row.count){
        if(row.price){
          row.amount =  Number((row.price *1).toFixed(2))
        }else if(!row.price || row.price == 0){
          row.amount =  0
        }
      }
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (row.taxRate || row.taxRate == 0) {
        tax = Number(amount).toFixed(2) * Number(row.taxRate);
        row.tax = Number(tax.toFixed(2));
        total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
        row.total = Number(total.toFixed(2));
      }
      if(this.invoiceType == 3 || this.invoiceType == 8){
        row.total = Number(amount).toFixed(2) *1
      }
      this.computedSumAmount()
    },
    //输入金额
    changeAmount(row) {
      if(row.count && row.count != 0 ){
        row.price = Number((row.amount *1 / row.count *1).toFixed(2))
      }else if(row.count == 0 || !row.count){
        row.price = Number((row.amount*1).toFixed(2))
      }
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (row.taxRate || row.taxRate == 0) {
        tax = Number(amount).toFixed(2) * Number(row.taxRate);
        row.tax = Number(tax.toFixed(2));
        total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
        row.total = Number(total.toFixed(2));
      }
      if(this.invoiceType == 3 || this.invoiceType == 8){
        row.total = Number(amount).toFixed(2) *1
      }
      this.computedSumAmount()
    },
    computedSumAmount(){
      let count = 0;
      this.info.items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
      })
      this.sumAmount = count
      this.sumAmount = (this.sumAmount).toFixed(2)*1
    },
    // 上一张
    changeInvoicePre(content){
      let param = {
        id:content.id,
        type:'pre',
        from:'in'
      }
      invoiceNextInfo(param).then(res => {
        if(res.data.data.info.id == 0){
          this.$qzfMessage("已经是第一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addInInvoiceId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addIncome")
        this.$router.push({
          path: "/bookkeeps/addIncome",
          name: "addIncome"
        });
        this.$emit('success',res.data.data.info.invoiceType)
        this.infoItems = []
        this.infoIndex = 0
        this.init(res.data.data.info)
        
      })
    },
    changeInvoiceNext(content){
      let param = {
        id:content.id,
        type:'next',
        from:'in'
      }
      invoiceNextInfo(param).then(res => {
        if(res.data.data.info.id == 0){
          this.$qzfMessage("已经是最后一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addInInvoiceId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addIncome")
        this.$router.push({
          path: "/bookkeeps/addIncome",
          name: "addIncome"
        });
        this.$emit('success',res.data.data.info.invoiceType)
        this.infoItems = []
        this.infoIndex = 0
        this.init(res.data.data.info)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
  .top_title {
    width: 100%;
    margin: 0 auto;
    h5 {
      font-size: 30px;
      color: #333;
      text-align: center;
      font-weight: 500;
      margin-bottom: 36px;
    }
  }
  .top_input {
    width: 30%;
    h6 {
      float: left;
      width: 60px;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
    }
  }
  .top_input2 {
    //width: 30%;
    margin: 0 auto;
    position: relative;
    h6 {
      float: left;
      width: 60px;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      line-height: 28px;
      margin-right: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #008000;
    margin-top: 10px;
    border-right: none;
  }
  .grid-content {
    position: relative;
    min-height: 38px;
    font-size: 12px;
    color: #666;
    line-height: 38px;
    text-align: center;
  }
  .row_box2 {
    width: 100%;
    height: 154px;
  }
  .height_font {
    width: 100%;
    height: 152px;
    margin: 0 auto;
    text-align: center;
    p {
      width: 20px;
      margin: 0 auto;
      height: 100%;
      line-height: 28px;
      padding-top: 22px;
    }
  }
  .list_info {
    line-height: 24px;
    &.red li {
      border-bottom: 1px solid #d56400 !important;
    }
    li {
      position: relative;
      line-height: 38px;
      height: 38px;
      font-size: 12px;
      color: #333;
      border-bottom: 1px solid #008000;

      &:last-child {
        border-bottom: none;
      }
      input {
        width: 100%;
        margin: 0 auto;
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        color: #333;
        background: none;
        border: none;
        padding: 0 5px;
      }
    }
  }
  .weight {
    font-weight: 700;
  }
  .weight {
    li {
      font-weight: 600 !important;
    }
  }
  .star {
    color: #f15a24;
    height: 12px;
    font-size: 18px;
    display: inline-block;
    z-index: 999;
  }
  .input_box {
    width: 100%;
    height: 152px;
    font-size: 12px;
    color: #666;
    // line-height: 38px;
    text-align: center;
  }
  .top_font {
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 39px;
    font-size: 12px;
    color: #333;
  }
  .item_box {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 9px;
      font-size: 20px;
      color: #ff1818;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -28px;
      top: 9px;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    input {
      width: 100%;
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      font-size: 12px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
  .item_list {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .add {
      position: absolute;
      right: -38px;
      top: 10px;
      width: 1.8em;
      height: 1.8em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .bg-purple {
      line-height: 38px;
      height: 38px;
      font-size: 12px;
      color: #333;
      border-bottom: 1px solid #008000;
      // position: relative;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .bg-purple {
    text-align: center;
  }
  .bottom_num {
    width: 20%;
    margin-top: 10px;
    font-size: 12px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
  }
  .total {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    // border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 38px;
    font-size: 12px;
    color: #333;
  }
 .el-row {
  width: 100%;
  }
 .el-col {
  border-right: 1px solid #008000;
  }
  .special h5 {
    color: #d56400;
    border-bottom: 4px double #d56400;
    span {
      border-bottom: 16px solid #d56400;
    }
  }
  .special .el-col {
    border-right: 1px solid #d56400;
  }
  .special .content {
    border: 1px solid #d56400;
    border-right: none;
  }
  .special .list_info li {
    border-bottom: 1px solid #d56400;
    &:last-child {
      border-bottom: none;
    }
  }
  .special .textarea {
    border-bottom: 1px solid #d56400;
  }
  .special .top_font {
    border-bottom: 1px solid #d56400;
  }
  .special .item_list {
    border-top: 1px solid #d56400;
  }
  .special .item_box {
    border-top: 1px solid #d56400;
  }
  .special .total {
    border-top: 1px solid #f15a24;
  }
  .red {
    border-color: #d56400 !important;
  }
</style>


<style scoped>
.invoice-radio .el-radio__label{
  padding-left: 0 !important;
}
.invoice-radio .el-radio{
  margin-right: 0 !important;
}
.invoice .row_box2 :deep(.el-input__inner){
  height: 36px;
  /* border: none; */
}
.loadmore {
  text-align: center;
}
</style>